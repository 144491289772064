/* Медиазапрос для маленьких экранов (мобильные устройства в портретной ориентации) */
@media (max-width: 480px) {
    :root {
        --square-carusel-size: 250px;
    }
  }
  
  /* Медиазапрос для мобильных устройств в альбомной ориентации и небольших планшетов */
  @media (min-width: 481px) and (max-width: 768px) {
    :root {
        --square-carusel-size: 350px;
    }
   
  }
  
  /* Медиазапрос для средних экранов */
  @media (min-width: 769px) and (max-width: 1200px) {
    :root {
        --square-carusel-size: 300px;
    }
   
  }
  
  /* Медиазапрос для больших экранов */
  @media (min-width: 1201px) {
    :root {
        --square-carusel-size: 400px;
    }
  }

.square-carousel {
    margin-top: 3vw;
    margin-bottom: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: var(--square-carusel-size); /* Установите желаемую высоту для карусели */
    background-color: #00000000;
}

.square-image {
    width: var(--square-carusel-size); /* Установите желаемый размер квадратного изображения */
    height: var(--square-carusel-size); /* Установите желаемый размер квадратного изображения */
    background-size: cover;
    background-position: center;
    margin: 0 auto;
}