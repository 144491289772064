@import url('https://fonts.googleapis.com/css?family=Cinzel+Decorative|Archivo+Black|Raleway|Playfair+Display|Abril+Fatface&display=swap');

@font-face {
  font-family:'Figurny';
  src: url('StudioScriptC.otf');
}

@font-face {
  font-family:'Segoe';
  src: url('Segoe_Script.ttf');
}

@font-face {
  font-family:'Scriptorama';
  src: url('Scriptorama.ttf');
}

@font-face {
  font-family:'Saksonia';
  src: url('Saksonia.ttf');
}

@font-face {
  font-family:'Andantino';
  src: url('Andantino_script.ttf');
}

body {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

/* Общий стиль для текста */
.text-menu {
  font-size: 14px;
  text-align: center;
}

/* Медиазапрос для маленьких экранов (мобильные устройства в портретной ориентации) */
@media (max-width: 480px) {
  :root {
    --height-menu: 55px;
    --font-tel: 12px;
    --size-tel: 23px;
    --size-whatsapp: 32px;
    --size-telegramm: 28px;
    --size-vk: 36px;
    --size-viber: 26px;
    --font-title: 18px;
    --header-img-width: 70vw;
    --logo-size:10vw;
    --logo-margin-left: -1vw;
    --logo-margin-right: -3vw;
    --logo-padding-left: 1vw;
    --logo-padding-right: 1vw;
    --logo-padding-top: 2.5vw;
    --logo-padding-bottom: 3vw;
    --font-size-btn-up: 12px;
    --size-btn-up:50px;
    --font-size-title:50px;
    --padding-top-font-title: 10vh;
    --service-font-size: 18px;
    --contract-h-font-size: 28px;
    --contract-t-font-size: 28px;
    --service1-font-size: 18px;
    --service-img-size: 150px;
    --contract-img-size: 175px;
    --contacts-h-text-size: 20px;
    --contacts-form-telefon-text-size: 18px;
    --content-privacy-margin-rl: 0px;
    --content-privacy-pading: 5px;
    --btn-panel-privacy-width: calc(100% - 0px);
    --feedback-form-btn-size-font: 12pt;
  }
  .text-menu{
    font-size: 12px; /* Например, 3.5% ширины экрана */
  }
}

/* Медиазапрос для мобильных устройств в альбомной ориентации и небольших планшетов */
@media (min-width: 481px) and (max-width: 768px) {
  :root {
    --height-menu: 55px;
    --font-tel: 14px;
    --size-tel: 35px;
    --size-whatsapp: 44px;
    --size-telegramm: 40px;
    --size-vk: 38px;
    --size-viber: 38px;
    --font-title: 58px;
    --header-img-width: 70vw;
    --logo-size:11vw;
    --logo-margin-left: -1vw;
    --logo-margin-right: -2vw;
    --logo-padding-left: 1vw;
    --logo-padding-right: 1vw;
    --logo-padding-top: 2.5vw;
    --logo-padding-bottom: 3vw;
    --font-size-btn-up: 12px;
    --size-btn-up:50px;
    --font-size-title:74px;
    --padding-top-font-title: 10vh;
    --service-font-size: 22px;
    --contract-h-font-size: 28px;
    --contract-t-font-size: 28px;
    --service1-font-size: 18px;
    --service-img-size: 150px;
    --contract-img-size: 175px;
    --contacts-h-text-size: 20px;
    --contacts-form-telefon-text-size: 18px;
    --content-privacy-margin-rl: 25px;
    --content-privacy-pading: 50px;
    --btn-panel-privacy-width: calc(100% - 50px);
    --feedback-form-btn-size-font: 12pt;
  }
  .text-menu {
    font-size: 14px; /* Например, 2.5% ширины экрана */
  }
}

/* Медиазапрос для средних экранов */
@media (min-width: 769px) and (max-width: 1200px) {
  :root {
    --height-menu: 50px;
    --font-tel: 20px;
    --size-tel: 35px;
    --size-whatsapp: 40px;
    --size-telegramm: 35px;
    --size-vk: 30px;
    --size-viber: 30px;
    --font-title: 28px;
    --header-img-width: 60vw;
    --logo-size:10vw;
    --logo-margin-left: 0vw;
    --logo-margin-right: -1vw;
    --logo-padding-left: 2vw;
    --logo-padding-right: 1vw;
    --logo-padding-top: 2.5vw;
    --logo-padding-bottom: 3vw;
    --font-size-btn-up: 14px;
    --size-btn-up:60px;
    --font-size-title:92px;
    --padding-top-font-title: 15vh;
    --service-font-size: 28px;
    --contract-h-font-size: 30px;
    --contract-t-font-size: 28px;
    --service1-font-size: 18px;
    --service-img-size: 150px;
    --contract-img-size: 175px;
    --contacts-h-text-size: 25px;
    --contacts-form-telefon-text-size: 18px;
    --content-privacy-margin-rl: 50px;
    --content-privacy-pading: 100px;
    --btn-panel-privacy-width: calc(100% - 100px);
    --feedback-form-btn-size-font: 14pt;
  }
  .text-menu {
    font-size: 18px; /* Например, 2rem (относительные единицы) */
  }
}

/* Медиазапрос для больших экранов */
@media (min-width: 1201px) {
  :root {
    --height-menu: 50px;
    --font-tel: 26px;
    --size-tel: 35px;
    --size-whatsapp: 42px;
    --size-telegramm: 40px;
    --size-vk: 35px;
    --size-viber: 35px;
    --font-title: 44px;
    --header-img-width: 45vw;
    --logo-size:10vw;
    --logo-margin-left: 0vw;
    --logo-margin-right: 0vw;
    --logo-padding-left: 2vw;
    --logo-padding-right: 1vw;
    --logo-padding-top: 2.5vw;
    --logo-padding-bottom: 3vw;
    --about-text-font-size: 22px;
    --font-size-btn-up: 18px;
    --size-btn-up:80px;
    --font-size-title:100px;
    --padding-top-font-title: 15vh;
    --service-font-size: 28px;
    --contract-h-font-size: 38px;
    --contract-t-font-size: 28px;
    --service1-font-size: 24px;
    --service-img-size: 150px;
    --contract-img-size: 210px;
    --contacts-h-text-size: 28px;
    --contacts-form-telefon-text-size: 28px;
    --content-privacy-margin-rl: 50px;
    --content-privacy-pading: 100px;
    --btn-panel-privacy-width: calc(100% - 100px);
    --feedback-form-btn-size-font: 14pt;
  }
  .text-menu {
    font-size: 18px; /* Например, фиксированный размер шрифта */
  }
}


.App {
  margin: 0;
  padding: 0;
}

.App-header {
  background-image: url("fon4.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.header{
  padding-top: 1vw;
}

.App-content {
 
}

.App-up{
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: var(--size-btn-up);
  height: var(--size-btn-up);
  background-color: black;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-btn-up);
  visibility: hidden;
}

.App-up a{
  color: white;
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-up.visible {
  visibility: visible;
}

.imglogo{
  width: var(--logo-size);
  height: var(--logo-size);
}

.main-logo{
  margin-left: var(--logo-margin-left); /*-1vw;*/
  margin-right: var(--logo-margin-right);/*-3vw;*/
  padding-left: var(--logo-padding-left);/*1vw;*/
  padding-right: var(--logo-padding-right);/*1vw;*/
  padding-top: var(--logo-padding-top);/*2.5vw;*/
  padding-bottom: var(--logo-padding-bottom);/*3vw;*/
  background-color: #b2b3b3BB;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.mainmenu{
  background-color: #2e4a46ff;
  width: 100%;
  height: var(--height-menu);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.menu {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  height: var(--height-menu); 
}

.menu li:not(:first-child) {
  border-left: 1px solid #ccc;
}

.menu li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  height: var(--height-menu);
}

.menu a {
  text-decoration: none;
  color: white;
  transition: color 0.3s;
}

.menu a:hover {
  color: #ff6600;
}

.contacts{
  list-style: none;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--height-menu);
  cursor: pointer;
  font-size: var(--font-tel);
  color: black;
  text-decoration: none;
}

.header-contacts {
  margin-top: 1vw;
  display: flex; /* Позволяет держать элементы в одной строке */
  align-items: center; /* Выравнивание по вертикали */
  justify-content: flex-end;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  background-color: #b2b3b3BB;
}

.header-contacts a,
.header-contacts .mya{
  display: inline-flex; /* Позволяет держать изображение и текст в одной строке */
  align-items: center; /* Выравнивание по вертикали */
  margin-right: 10px; /* Промежуток между элементами */
  text-decoration: none; /* Убираем стандартное подчеркивание ссылок */
}

.header-contacts p {
  margin: 2px; /* Убираем отступы у параграфа */
}

.imgtel{
  width: var(--size-tel);
  height: var(--size-tel);
  margin-right: 2px;
}

.imgwhatsapp{
  width: var(--size-whatsapp);
  height: var(--size-whatsapp);
}

.imgtelegramm{
  width: var(--size-telegramm);
  height: var(--size-telegramm);
}

.imgvk{
  width: var(--size-vk);
  height: var(--size-vk);
}

.imgviber{
  width: var(--size-viber);
  height: var(--size-viber);
}

.header-contacts a:hover,
.header-contacts .mya:hover{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.header-contacts a:active,
.header-contacts .mya:active{
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
}

.header-title{
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1vw;
  padding-top: var(--padding-top-font-title);
  margin-bottom: 4vw;
  font-size: var(--font-title);
  color: #2e4a46ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  height: calc(100vh - 3 * var(--height-menu) - 2vw);
}

.carusel-menu{
  padding: 0.5vw;
  margin-top: 1vw;
  height: auto;
  width: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  background-color: #00000055;
}

.cphoto-container {
  height: calc(100vh - 3 * var(--height-menu) - 2vw); /* Устанавливает высоту элемента по высоте окна браузера */
  display: flex;
  justify-content: center;
  align-items: center;
}

.cphoto {
  max-height: 100%; /* Максимальная высота изображения 100% от высоты контейнера */
  max-width: 100%; /* Максимальная ширина изображения 100% от ширины контейнера */
  object-fit: contain; /* Сохраняет пропорции изображения */
}

.about-content{
  margin: 3vw;
  /*background-color: #b2b3b322;*/
  background-color: #000000CC;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.header-img {
  width: var(--header-img-width);
}

.head-about{
  padding: 1vw;
  color: #ed521a;
}

.text-about{
  padding: 2vw;
  color: white;
  text-align: justify;
  font-size: var(--about-text-font-size);
}

.red-indent {
  display: inline-block;
  text-indent: 2em;
}

.relief-text{
  font-family: Andantino;
  font-size: var(--font-size-title);
  font-weight: bolder;
 
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  background-color: #b2b3b3BB;*/
  text-shadow: 
    0px 0px 5px rgba(255, 255, 255, 0.8),
    5px 0px 0px rgba(255, 255, 255, 0.7),
    0px 5px 0px rgba(255, 255, 255, 0.6),
    5px 5px 5px rgba(255, 255, 255, 0.5),
    0px 0px 10px rgba(255, 255, 255, 0.4),
    0px 10px 10px rgba(255, 255, 255, 0.3),
    10px 0px 10px rgba(255, 255, 255, 0.2),
    10px 10px 0px rgba(255, 255, 255, 0.1);
}

.services-content{
  margin: 3vw;
  padding: 1vw;
  /*background-color: #b2b3b322;*/
  /*background-color: #000000CC;*/
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
}

.head-services{
  padding: 1vw;
  color: #2e4a46ff;
  font-weight: bold;
}

.service{
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  border: 2px solid #000000CC; 
  cursor: pointer;
  margin-bottom: 2vw;
  padding: 1vw;
}

.service:hover{
  box-shadow: 0px 4px 8px rgba(237, 82, 26, 0.75),
  1px 5px 10px rgba(237, 82, 26, 0.55),
  2px 7px 16px rgba(237, 82, 26, 0.45);
  border: 1px solid rgba(237, 82, 26, 1); 
  cursor: pointer;
}

.service:active{
  box-shadow: -1px 2px 2px rgba(237, 82, 26, 0.75),
  -1px 1px 1px rgba(237, 82, 26, 0.55),
  -2px 0px 0px rgba(237, 82, 26, 0.45);
  border: 1px solid rgba(237, 82, 26, 1); 
  cursor: pointer;
}

.img-service{
  height: var(--service-img-size);
  width: auto;
  display: flex;
  align-items: center;
}

.text-service{
  color: #2e4a46ff;
  font-size: var(--service-font-size);
  display: flex;
  align-items: center;
  /*width: 100%;*/
  height: 100%; 
}


.text-service1{
  padding: 2vw;
  color: white;
  font-size: var(--service1-font-size);
  font-weight: bold;
  text-align: justify;
  background-color: #000000BB;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  text-indent: 2em;
}

.contract{
  margin: 3vw;
  padding: 10vw;
  background-image: url("fon-contract.png");
  background-color: rgba(237, 82, 26, 0.7);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  text-shadow: 0 0 10px white,
  10px 0 10px white,
  10px 10px 10px rgba(237, 82, 26, 0.7); 
}

.img-contract{
  height: var(--contract-img-size);
  width: auto;
  display: flex;
  align-items: center;
}

.text-h-contract{
  font-weight: bold;
  color: black;
  font-size: var(--contract-h-font-size);
  display: flex;
  align-items: center;
  height: 100%; 
}

.text-t-contract{
  color: black;
  font-size: var(--contract-t-font-size);
  display: flex;
  align-items: center;
  height: 100%; 
}

.contacts-footer{
  margin: 3vw;
  padding: 1vw;
  color: white; 
  text-shadow: 0 0 10px black,
  10px 0 10px black,
  10px 10px 10px black; 
  background-image: url("fon-contacts.png");
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.75);
  background-color: rgba(255, 255, 255, 0.6);
}

.contacts-h-text{
  font-size: var(--contacts-h-text-size)
}

.contacts-form{
  color:white;
  font-size: var(--contacts-form-telefon-text-size);
  text-decoration: none;
}

.contacts-form a{
  color:white;
  text-decoration: none;
}

.contacts-telefon{
  display: flex;
  border-radius: 5px;
  border: 1px solid white; 
  margin: 1vw;
  padding: 1vw;
  cursor: pointer;
  text-decoration: none;
}

.contacts-telefon:hover{
  box-shadow: 4px 4px 8px rgba(255, 255, 255, 1),
  -4px -4px 8px rgba(255, 255, 255, 1); 
}

.contacts-telefon:active{
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.55); 
}

.message-copy-phone {
  position: fixed;
  top: 0vw;
  left: 10vw;
  right: 10vw;
  width: auto;
  background-color: rgba(0, 0, 0, 0.55); /* Цвет фона */
  color: white; /* Цвет текста */
  text-align: center;
  padding: 10px;
  z-index: 1000; /* Установите нужный z-index для позиционирования над другими элементами */
}

.btnfeedback{
  font-size: larger;
  height: 80px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.privacy-policy{
  color: rgba(237, 82, 16, 1);
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.privacy-policy:hover{
  background-color: rgba(0, 0, 0, 0.55);
}

.privacy-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
  overflow: hidden;
}

.content-privacy {
  margin-left: var(--content-privacy-margin-rl);
  margin-right: var(--content-privacy-margin-rl);
  padding: var(--content-privacy-pading);
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /*max-width: 1000px;*/
  text-align: left;
  overflow-y: auto; 
  max-height: calc(100% - 200px);
  flex: 1; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
}

.close-button-privacy {
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.btn-panel-privacy{
  background-color: white;
  display: flex;
  justify-content: right;
  align-items: center;
  width: var(--btn-panel-privacy-width);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid black;
}

.hidden-content{
  visibility: hidden;
  /*overflow: hidden;
  position: absolute; /* Фиксированная позиция чтобы не вызывать прокрутку */
  /*top: 2000;
  left: 2000;
  width: 100%;
  height: 100%;*/
}

.feedback-form-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  min-width: 300px;
  min-height: 400px;
}

.feedback-form-modal button{
  font-size: var(--feedback-form-btn-size-font);
}

.feedback-form-modal .row{
  margin: 10px;
  padding: 5px;
}

.feedback-form-modal textarea{
  min-height: 120px;
  width: 100%;
}

.feedback-form-modal input{
  width: 100%;
}

.feedback-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}